@import "styleUtil";

.cmp-heroBlade {
  background-image: url("../assets/hero-bg-left.png"),
    url("../assets/hero-bg-right.png");
  background-color: #f4f4f4;
  background-position: top left, top right;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 120px 40% 105px 100px;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    background-image: none;
    padding: 45px 40px;
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    padding: 80px 50% 80px 75px;
  }

  h1 {
    margin: 0 0 10px 0;
    font-size: 24px;
  }
  h2 {
    margin: 0;
    font-weight: 200;
    font-size: 18px;
  }
}

.heroBlade-link{
  color:#2680eb; 
}

.heroBlade-link-container{
  margin-top: 15px;
}