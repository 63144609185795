@import "styleUtil";

// Filter Common override
.cmp-multiSelectFilter{
  .clearFilterButton {
    &[class*="focus-ring"] {
      .msf-applied-results {
        display: none;
      }
      .clearFilter {
        display: flex;
      }
    }
    &.selected:hover {
      .msf-applied-results {
        display: none;
      }
    }
  }
}

// Global
.clearSelectionButton {
  color: $color-link;
  background-color: $color-transparent !important;
}

// Desktop View
.msf-dialog {

  section[class^="spectrum-Dialog-content"] {
    max-height: 300px;
  }

  div[class^="spectrum-Dialog-grid"] {
    display: flex !important;
    flex-direction: column;
    padding: 20px 20px 20px 10px;
  }

  h2 {
    padding-left: 10px;
    color: #505050;
  }

  h4 {
    font-size: 15px;
  }

  .divider {
    position: relative;
    left: -10px;
    width: 108%;
    background-color: #eaeaea;
    height: 1px;
    margin: 16px 0;
  }

  .divider-short {
    width:100%;
    position: relative;
    background-color: #eaeaea;
    height: 1px;
    margin: 16px 0;
  }

  .checkbox-container {
    max-height: 280px;
    display: flex;
    flex-direction: column;
    padding-left: 23px;
  }

  #button-group {
    padding: 0;
  }

  .filterContent-bottom-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
  }

  .apply-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      margin-left: 15px;
    }
  }
}

// Mobile View
.mobile-checkbox-container {
  height: 300px;
  display: flex;
  flex-direction: column;
}

.mobile-filterContent-bottom-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#button-group {
  display: block;
}

.mobile-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobile-apply-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 135px;
}

.multi-select-filter-description {
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
  @media (max-width: $mobile-max) {
    right: 50px;
    top: 9px;
  }
}

.multi-select-filter-description-country {
  cursor: pointer;
  margin-left: 5px;
  @media (max-width: $mobile-max) {
  }
}

.physical-location-toggle {
  margin-right:0!important;
  font-weight:normal;
  margin-left: auto;
  cursor: pointer;

  input {
    cursor: pointer;
  }

  @media (max-width: $mobile-max) {
    display:block;
  }
}

.multi-select-description-text {
  a {
    text-decoration: underline;
    color: #1473e6;
  }
}

.multi-select-tooltip-content {
  @media (max-width: $mobile-max) {
    padding-right: 25px;
  }
}