.cmp-viewPhoneButton {
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    fill: #4b4b4b;
    transition: all 0.25s ease-out;
  }
}