@import "styleUtil";

.solution-brief-content {
  display: grid;
  grid-template-columns: 50% 50%;

  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    display: flex;
    flex-direction: column;
  }
}

.solution-brief-container {
  position: relative;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 20px 20px 20px 20px;
  margin-right: 32px;
  margin-bottom: 32px;

  .data-badge {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 13px;
    background: #ffcf0d;
    color: #69570f;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 4px 10px;
  }

  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    margin-right: 0px;
  }
}



.solution-brief-container:nth-last-child() {
  margin-right: 0px;
}

.solution-brief-text {
  margin-top: 15px;
  margin-bottom: 20px;

  .solution-brief-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .solution-brief-description {
    color: #858585;
  }
}

.solution-brief-industry {
  margin-bottom: 20px;

  .solution-brief-industry-title {
    font-size: 12px;
    color: #7b7b7b;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .industries {
    font-weight: bold;
  }
}

.soution-brief-solutions {
  margin-bottom: 20px;

  .solution-brief-solutions-title {
    font-size: 12px;
    color: #7b7b7b;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .solutions {
    display: flex;
    flex-direction: row;
    font-weight: bold;
  }
}

.solution-brief-button {
  margin-bottom: 50px;
}

.related-resources {
  margin-top: 20px;

  .related-resources-title {
    font-size: 12px;
    color: #7b7b7b;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .related-resources-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .resource-link-button {
      margin-top: 5px;

      a {
        text-decoration: none;
        color: #2680eb;
        font-weight: bold;
        vertical-align: middle;
        transition: color 0.2s;
      }

      svg {
        fill: #2680eb;
        margin-right: 8px;
        vertical-align: middle;
        transition: fill 0.2s;
      }

      &:hover {
        a {
          color: #0d66d0;
        }
        svg {
          fill: #0d66d0;
        }
      }
    }
  }
}


.solution-website{
  margin-bottom: 20px;

  .solution-website-title {
    font-size: 12px;
    color: #7b7b7b;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .solution-website-link {
    word-break: break-all;
  }

    a {
        color: #2680eb; 
    }

}