@import "styleUtil";

.filterButton {
  span {
    padding: 0 10px;
  }
  .caret {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      padding: 0 5px !important;
      transition: transform 0.4s;
    }
  }
  &:focus,
  &:hover {
    border-color: $color-bright-blue;
    color: $color-bright-blue;
    .caret svg {
      fill: $color-bright-blue;
    }
  }
  &.selected {
    background-color: $color-light-grey;
    border-color: $color-dark-grey;
    color: $color-light-black;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    .caret {
      display: none;
    }
  }
  &[class*="is-active"],
  &[class*="is-active"] ~ .clearFilterButton {
    .smallCaret svg {
      transform: scaleY(-1);
    }

    .clearFilter {
      display:none!important;
    }

    &.selected:hover {
      .smallCaret {
        display: flex;
      }

      .msf-applied-results {
        display: block;
      }
    }


  }
}

.clearFilterButton {
  display: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  min-width: 22px !important;
  .smallCaret,
  .clearFilter {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 11px;
      padding: 0 5px !important;
      transition: transform 0.4s;
    }
  }
  .separator {
    height: 100%;
    border-left: 1px solid $color-separator-grey;
    position: absolute;
    top: 0px;
  }
  .clearFilter {
    display: none;
  }
  &.selected {
    display: inline;
    background-color: $color-light-grey;
    border-color: $color-dark-grey;
    border-left: none;
  }
  &.selected:hover {
    .smallCaret {
      display: none;
    }
    .clearFilter {
      display: flex;
    }
  }
}

.filter-dialog-mobile {
  div[class*="is-selected"] div span {
    font-weight: bold;
    color: $color-blue;
  }
}

button[class*="spectrum-ActionButton"] {
  background-color: $color-white;
}

section[class*="spectrum-Dialog--dismissable"] {
  background-color: $color-white;
}

button[class*="spectrum-Dialog-closeButton"] {
  position: absolute;
  top: 0;
  right: 0;
}