@import "styleUtil";

.region-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
}

.region-container {
  .region-upper-section {
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eaeaea;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #fafafa;
    padding: 0px 0px 0px 25px;

    .region-name {
      font-weight: bold;
    }

    .specialized-box {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: 0px;
      background-color: #2196f3;
      color: white;
      height: 30px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 0 10px;

      svg {
        height: 16px;
        width: 16px;
      }
      span {
        margin-left: 6px;
      }
    }
  }

  .region-lower-section {
    height: calc(100% - 45px);
    border: 1px solid #eaeaea;
    border-top: 0px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px 25px;

    .staff-role {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;
    }

    .staff-role:nth-child(1) {
      margin-top: 0px;
    }
  }
}
