@import "styleUtil";

.form-submit-button {
  display: flex;
  justify-content: center;
  margin-top: 30px !important;
  
  .send-message-partner-button {
    cursor: pointer;
  }
}

.form-title-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.partnerName {
  font-size: 16px;
  font-weight: bold;
  color: $color-medium-grey;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.partnerMessage {
  font-size: 20px;
  font-weight: bold;
  width: 450px;
  text-align: center;

  @media (max-width: $mobile-max) {
    width: 100%;
  }
}

.form-container {
  align-items: center;

  .contact-field-container {
    flex-direction: row;
    @media (max-width: $mobile-max) {
      flex-direction: column;
    }
  }

  @media (max-width: $mobile-max) {
    margin-bottom: 20px;
  }
}

.contact-partner-form-container {
  background-color: $color-light-grey;
  padding: 50px 0;

  @media (max-width: $mobile-max) {
    padding: 0;
  }
}

.contact-partner-form {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 60px 100px;
  background-color: $color-white;
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: $mobile-max) {
    background-color: $color-light-grey;
    border-radius: 0;
    padding: 60px 40px;
  }

  .recaptcha-tos {
    text-align: center;
    color: #bcbcbc;
    font-size: 12px;
    padding-top: 12px;

    a {
      color: #1473e6;
      text-decoration: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.successMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  height: 300px;

  @media (max-width: $mobile-max) {
    height: 100%;
  }
}

.flex-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-end;

  @media (max-width: $mobile-max) {
    align-items: flex-start;
  }
}
