@import "styleUtil";

.solution-expertise-container {
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.solution-expertise-divider {
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    display: none;
  }
}

.expertise-container-heading {
  margin-top: 30px;
  margin-bottom: 30px;

  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .expertise-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .expertise-description {
    font-size: 16px;
  }
}

.solution-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .solution-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      margin-bottom: 15px;
    }

    .ribbon-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      margin-right: 20px;
      color: #6e6e6e;
      background-color: #eaeaea;

      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        margin-right: 10px;
      }
    }
  }

  .solution-name {
    font-size: 20px;
    font-weight: bold;
  }

  .solution-certification {
    color: #505050;
  }

  .global-specialization {
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #ffdb00;
    border: 1px solid #ffdb00;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0px 16px 0px 16px;
    text-transform: uppercase;

    .globe-icon {
      height: 15px;
      width: 15px;
      margin-right: 5px;
    }

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      align-self: center;
      margin-bottom: 20px;
    }
  }
}

.region-section-row {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 20px;

  /* Mobile View */
  @media only screen and (min-width: $mobile-min) and (max-width: 1060px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }

  .region-container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    @media only screen and (min-width: $mobile-min) and (max-width: 1060px) {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
}

.region-container:nth-child(3n) {
  margin-right: 0px;
}

.deploymentSectionInfo{
  margin-bottom: 20px;
  margin-top: -25px;
}

.expertise_description_2{
  margin-top: 10px;
}