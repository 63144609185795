.specialized-dialog {
  div[class^="spectrum-Dialog-grid"] {
    display: flex !important;
    flex-direction: column;
    padding: 20px 0px 20px 20px;
  }
}

.specialized-top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.specialized-dropdown-title {
  width: 75%;
}

.specialized-dropdown-content {
  padding-top: 20px;
   a {
    margin-left: 1px;
    text-decoration: underline;
    color: #1473E6;
  }
}

.specialized-toggle-switch {
  position: relative;
  bottom: 5px;
  input{
    cursor: pointer;
  }
  
}