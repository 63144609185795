@import "styleUtil";

.cmp-contentBlade {
  position: relative;
}

div[class^="contentBladeContainer"] {
  text-align: center;
  padding: 25px;
  background-color: #eaeaea;

  h3 {
    font-size: 20px;
  }

  a {
    text-decoration: none;
    background-color: $color-transparent !important;
    color: $color-link;
  }
}
