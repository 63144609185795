@import "styleUtil";

.detail-loading-overlay {
  position: absolute;
  height: 90%;
  width: 100%;
  background-color: white;
  z-index: 1;
}

.detail-loading-spinner {
  top: 15%;
  left: 50%;
}

#detail-page-container {
  grid-template-areas: ". info main .";
  grid-template-columns: 2fr 250px 12fr 2fr;
  grid-gap: 30px;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    grid-template-areas: "info" "main";
    grid-template-columns: none;
    grid-template-rows: auto auto;
    grid-gap: 5px;
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    grid-template-columns: 25px 200px 8fr 25px;
  }

  .info-section {
    margin-top: -70px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      margin-top: -170px;
      padding: 0 25px;
    }
  }

  .main-section {
    padding: 15px 0;

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      margin-top: 20px;
    }

    .partner-name-section {
      margin-bottom: 30px;
      h2 {
        margin-bottom: 0px;
      }
      .button-group {
        display: block;
        margin-top: 15px;
      }

      /* Smartphones (portrait and landscape) ----------- */
      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        display: none;
      }
    }

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      padding: 0 25px;
    }
  }

  .cmp-returnToResults {
    float: right;
    clear: both;
    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      display: none;
    }
  }

  .react-spectrum-TabView {
    margin-bottom: 50px;
    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      display: inline-grid;
    }
  }

  .spectrum-Tabs--horizontal {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    overflow-x: auto;

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      margin-bottom: 20px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .spectrum-Tabs-selectionIndicator {
      bottom: 0px;
    }

    .spectrum-Tabs-item {
      text-align: center;
    }
  }

  .tab-content-container {
    grid-template-areas: "tab-content .";
    grid-template-columns: 11fr 1fr;

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      grid-template-areas: "tab-content";
      grid-template-columns: 1fr;
    }
  }
}
