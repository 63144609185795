@import "styleUtil";

.filterContainer {
  grid-template-areas: "filters clear-button search";
  grid-template-columns: max-content minmax(100px, 3fr) minmax(250px, auto);
  grid-gap: 15px;

  /* Tablet */
  @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    grid-template-columns: auto minmax(100px, 3fr) minmax(250px, auto);
  }

  /* Mobile */
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    grid-template-areas: "search" "clear-button" "filters";
    grid-template-columns: none;
    grid-template-rows: auto auto auto;
    grid-gap: 5px;
  }

  #filter-section {
    max-width: 700px;

    .filter-flex-parent {
      display: inline-flex;
    }

    #filterIcon {
      display: none;

      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        display: inline;
        margin-right: 5px;
        position: relative;
        top: 6px;
      }
    }

    .filterLabel {
      margin-right: 5px;
      position: relative;
      top: 6px;
      font-weight: bold;

      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        display: none;
      }
    }

    #more-filters {
      border-width: thin;
      border-color: #eaeaea;

      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        display: none;
      }
    }

    #accredited-filter,
    #specialized-filter,
    #industry-filter,
    #level-filter {
      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        display: none;
      }
    }
  }

  .clear-all-motion-container {
    grid-area: clear-button / clear-button / clear-button / clear-button;
    border-left: 1px solid #dadada;
    padding-left: 10px;

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      display: none;
    }

    #clear-all-filters-button {
      color: #3284e9;
      display: inline;
      border: none;
    }
  }

  #result-search-section {
    display: inline-flex;
    align-items: baseline;
    justify-content: flex-end;

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      justify-content: flex-start;
    }

    .resultsCounter {
      margin-right: 20px;

      #result-count {
        font-weight: bold;
      }

      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        display: none;
      }
    }
  }
}