.cmp-loadMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    cursor: pointer;
  }
}

.displayed-results {
  min-height: 32px;
}
