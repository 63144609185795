.cmp-footer {
  min-height: 50px;
  background: #000;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  .spectrum-grid--fixed {
    max-width: 1920px !important;
  }
  .footer-row {
    display: flex;
    align-items: center;
    min-height: 50px;
  }
  ul.footer {
    font-size: 13px;
    color: #999;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      &::before {
        content: "/";
        margin: 0 5px;
      }
      &:first-of-type:before {
        content: "";
      }
      a {
        text-decoration: none;
        color: #737373;
        transition: color 0.1s ease;
        &:hover {
          color: white;
        }
      }
    }
  }
  a.helpdesk {
    color: #737373;
    text-decoration: none;
    transition: color 0.1s ease;
    svg,
    span {
      vertical-align: middle;
    }
    svg {
      margin-right: 10px;
    }
    &:hover {
      color: white;
    }
  }
  button.openPrivacyModal {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: adobe-clean, "Source Sans Pro", -apple-system,
      BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    transition: color 0.1s ease;
    color: #737373;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
}
