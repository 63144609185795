@import "styleUtil";

.cmp-content-card {
  border: 1px solid#EAEAEA;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-out;

  &.card-width-1 {
    flex: 3 3 30%;
  }

  &.card-width-2 {
    flex: 2 2 45%;
  }

  &.card-width-3 {
    flex-basis: 100%;
  }

  @media (max-width: $mobile-max) {
    flex-basis: 100% !important;
  }

  &.as-button {
    &:hover,
    &:focus {
      cursor: pointer;
      user-select: none;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      border: 1px solid #cccccc;
      outline: none;
    }

    &:active {
      transform: scale(0.995);
    }
  }

  .ribbon {
    position: absolute;
    text-transform: uppercase;
    right: 0;
    top: 15px;
    background-color: #eaeaea;
    color: #6e6e6e;
    font-weight: bold;
    padding: 5px 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    letter-spacing: 0.05em;
    font-size: 12px;
    z-index: 10;
  }

  .thumbnail {
    height: 260px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative;

    .playIcon {
      position: absolute;
      right: 15px;
      bottom: 15px;
      background: #fff;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      svg {
        fill: #1473e6;
      }
    }
  }

  .content-section {
    padding: 25px;

    &.hasRibbon {
      padding-top: 50px;
    }

    h1 {
      margin: 0px;
      color: #323232;
      font-size: 16px;
    }

    h1 + p {
      margin-top: 15px;
    }

    p {
      color: #747474;
      font-size: 14px;
      margin: 0;
    }

    .link {
      margin-top: 15px;
      a {
        text-decoration: none;
        color: #2680eb;
        font-weight: bold;
        vertical-align: middle;
        transition: color 0.2s;
      }
      svg {
        fill: #2680eb;
        margin-right: 8px;
        vertical-align: middle;
        transition: fill 0.2s;
      }
      &:hover {
        a {
          color: #0d66d0;
        }
        svg {
          fill: #0d66d0;
        }
      }
    }
  }
}
