@import "styleUtil";

.tab-content {
  a {
    text-decoration: none !important;
    color: #1473e6;
  }
  .industries-section {
    ul.industries {
      list-style-type: none;
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      column-gap: 40px;

      @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        padding: 0;
      }

      li {
        color: #323232;
        font-weight: bold;
        font-size: 16px;
        line-height: 38px;
        &:before {
          content: "•";
          font-size: 16px;
          float: left;
          margin-right: 20px;
          color: #d3d3d3;
          position: relative;
          top: -1px;
        }
        .industry-list-item {
          padding-left: 26px;
        }
      }
    }
  }
}
