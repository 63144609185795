@import "styleUtil";

.heroBanner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-banner-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-position: center;
}

@media only screen and (max-width: $tablet-min) {
  .hero-banner-image {
    min-height: 200px;
    background-position: 0%;
  }
}
