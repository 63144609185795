/* Header Component */

.cmp-header {
  min-height: 64px;
  background: #ffffff;
  line-height: 1;
  border-bottom: 1px solid #eaeaea;
  .header-grid {
    max-width: 1920px !important;
  }
  .col-app-title {
    display: flex;
    align-items: center;
    min-height: 64px;
    .app-title {
      font-size: 18px;
      text-decoration: none;
      color: inherit;
      padding: 10px 0;
      &.clickable {
        cursor: pointer;
      }
      .adobe-icon {
        vertical-align: middle;
        margin-right: 10px;
        color: red;
      }
      .app-name {
        font-weight: 300;
        vertical-align: middle;
        .bold-prefix {
          font-weight: bold;
        }
        .text {
          margin-left: 5px;
        }
      }
    }
  }

  .ims-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

#feds-header {
  height: 65px;
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 10 !important;
  transition: transform .3s ease;
  z-index: -1;
}

.topnavSpacing {
  transition: height .3s ease;
}

.Profile-header,
.Profile-avatar {
  pointer-events: none;
}

.Profile-email {
  margin: 0 !important;
}

.Profile-menu-link[data-profile="sign-out"] {
  font-weight: normal !important;
  color: #2680EB !important;
  text-transform: uppercase !important;
}

.Profile-localLinks-title {
  color: #4b4b4b !important;
  font-size: 16px !important;
  font-weight: normal !important;
  pointer-events: none !important;
  text-transform: none !important;
  padding-bottom: 0 !important;
}


.Profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile-header-cta {
  display: none !important;
}

.sign-out {
  font-weight: normal;
  font-size: 16px !important;
  color: #2680EB;
  text-transform: uppercase;
  padding: 10px 20px;
  cursor: pointer;
}

.sign-out:hover {
  background-color: #F5F5F5;
}

.profile-action-btn {
  font-size: 16px !important;
  font-weight: normal !important;
  color: #2680EB !important;
  padding: 3px 20px !important;
}

.company-info-title {
  padding-top: 12px !important;
  padding-bottom: 3px !important;
  pointer-events: none !important;
  font-size: 12px !important;
  font-weight: normal;
  color: #6e6e6e !important;
  text-transform: uppercase;
}

.company-info-value {
  color: #4b4b4b !important;
  pointer-events: none;
  padding: 3px 20px !important;
  font-size: 16px !important;
}

.applauncher-element[daa-ll="App Launcher"]{
  display: none !important;
}

// .feds-search-trigger {
//   display: none !important;
// }


.hide {
  display: none !important;
}

.search-content {
  display: block;
  padding: 10px 0;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #d3d3d3;
  border-radius: 0 0 3px 3px;
}

.show {
  display: block;
}

.feds-searchResult-links {
  display: block;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1;
  /* font-weight: 700; */
  color: #2d2d2d;
}
.feds-advancedSearch {
  padding: 10px 0;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #d3d3d3;
  border-radius: 0 0 3px 3px;
}

.feds-advancedSearch-link {
  color: blue !important;
  cursor: pointer;
}