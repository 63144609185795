.locationFilterContainer {
  display: inline;
  white-space: nowrap;
  .selected-location {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .country-filter {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
  }
  // Filter Common override
  .clearFilterButton {
    &.selected:focus{
      .smallCaret {
        display: none;
      }
      .clearFilter {
        display: flex;
      }
    }
  }
}

.single-select-listbox {
  max-height:100%!important;
}

.mobile-dialog-tooltip {
  padding-right: 25px;
}