/* Breakpoints */
$mobile-min: 240px;
$mobile-max: 768px;

$tablet-min: 769px;
$tablet-max: 1224px;

/* Colors */
$color-white: white;
$color-grey: #c8c8c8;
$color-separator-grey: #d3d3d3;
$color-light-grey: #fafafa;
$color-medium-grey: #747474;
$color-dark-grey: #505050;
$color-light-black: #323232;
$color-link: #3284e9;
$color-bright-blue: #1573e6;
$color-blue: #1473e6;
$color-red: red;
$color-light-gradient-black: rgba(0, 0, 0, 0.2);
$color-transparent: transparent;
