@import 'styleUtil';

.cmp-listing-card {
  flex: 1 0 30%;
  background-color: white;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-out;
  margin: 25px 0 0 25px;

  &:hover,
  &:focus {
    cursor: pointer;
    user-select: none;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    border: 1px solid #cccccc;
    outline: none;

    .logo-container {
      background-color: #bcbcbc;
    }
  }

  &:active {
    transform: scale(0.995);
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    flex: 1 0 100%;
    margin: 10px 25px;
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    flex: 1 0 40%;
  }

  .preloading-overlay {
    background: rgba(255, 255, 255, 0.9);
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5000;
    display: flex;
  }

  .top-right-ribbon {
    background-color: white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #eaeaea;
    border-right: none;
    padding: 5px 15px;
    position: absolute;
    right: 0px;
    top: 15px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.03em;
    color: #2c2c2c;
    font-size: 12px;
    z-index: 1;
  }

  .logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    background-color: #f5f5f5;
    transition: all 0.25s ease-out;

    .logo {
      width: 180px;
      height: 120px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      mix-blend-mode: multiply;
    }

    .award-info-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 20px;
      background: #6E6E6E;
      color: white;
      box-sizing: border-box;

      .award-info {
        margin-left: 10px;
      }
    }
  }

  .content-section {
    padding: 15px 25px;

    span.partner-level {
      font-size: 13px;
    }

    h1.partner-name {
      margin: 0;
      font-size: 16px;
    }

    span.short-description {
      margin-bottom: 20px;
      font-size: 12px;
      color: #747474;
      line-height: 18px;
      min-height: 60px;
      display: block;
    }

    .card-certification-details {
      display: flex;
      flex-direction: row;
    }

    .certified-employees {
      display: inline-flex;
      align-items: center;

      svg {
        color: #747474;
        margin-right: 8px;
        width: 15px;
      }

      span {
        color: #707070;
        word-break: break-all;
      }
    }

    .certifications {
      display: inline-flex;
      align-items: center;
      margin-left: 8px;

      svg {
        color: #747474;
        margin-right: 8px;
        width: 6px;
      }

      span {
        color: #707070;
        word-break: break-all;
      }
    }
  }
}

.cmp-listing-card-spacer {
  flex: 1 0 30%;
  margin: 25px 0 0 25px;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    display: none;
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    flex: 1 0 40%;
  }
}