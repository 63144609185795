.tab-content {
  h2 {
    color: #323232;
    font-size: 22px;
  }
  h3 {
    color: #505050;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 35px;
  }
  .divider {
    margin: 60px 0;
  }
  .resource-section {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 60px;
    padding-bottom: 80px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .cmp-video-card-spacer {
      flex: 2 2 45%;
    }
  }
}
