@import 'styleUtil';

.PartnerInfoCard {
  width: 100%;
  margin-bottom: 25px;
  .badgeIcon {
    svg{
      vertical-align: sub;
    }
  }
  .info-top-section {
    border: 1px solid $color-grey;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: $color-white;
    margin-bottom: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .partner-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;

      .partner-logo {
        height: 80px;
        width: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        font-size: 58px;
        color: #6e6e6e;
      }
    }

    @media (max-width: $mobile-max) {
      .partner-logo-container {
        height: 180px;
      }
    }

    .partner-level {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 20px 20px 20px;
      font-weight: bold;

      svg {
        height: 20px;
        margin-right: 8px;
      }
    }
  }

  .mobile-info-container {
    display: none;
  }

  @media (max-width: $mobile-max) {
    .mobile-info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .mobile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 30px 0 30px;
    text-align: center;

    .partner-name {
      font-weight: bold;
      font-size: 28px;
    }

    .partner-location {
      padding-bottom: 10px;
    }

    .button-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      button {
        flex-grow: 0;
        margin-right: 8px;
        margin-bottom: 8px;
      }
      .break {
        flex-basis: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
  .info-deployment-section{
    button {
    height:100%;
    padding: 10px 0px;
    }
    text-align: center;
    height: 58px;
    overflow-y: auto;
    margin-top: 24px;  
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 24px 20px;
    div.deployment-number {  
      display: inline-block;    
      font-weight: bold;
      font-size: 28px;        
      word-break: break-all;     
      text-align: left;
      height: 100%;
      margin-right: 6px;
    }  
    div.deployment-text {
      display: inline-block;
      margin-left: 8px;
      vertical-align: top;  
     
    }
  }

  .info-bottom-section {
    min-height: 188px;
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 10px 0px 10px 0px;

    svg {
      height: 18px;
      width: 20px;
      padding: 0;
    }
    .info-bottom-section-top,
    .info-bottom-section-bottom {
      display: flex;
      flex-direction: row;
      padding: 12px;
      justify-content: center;
    }

    .certifiedEmployees-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      cursor: pointer;

      .certifiedEmployees {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }

      .certifiedEmployees-number {
        font-weight: bold;
        font-size: 28px;
        margin-left: 5px;
        word-break: break-all;
      }
    }
    .specialization-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      cursor: pointer;

      .specialization {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }

      .specialization-number {
        font-weight: bold;
        font-size: 28px;
        margin-left: 5px;
        word-break: break-all;
      }
    }
    .certifications-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      cursor: pointer;

      .certification {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }

      .certification-number {
        font-weight: bold;
        font-size: 28px;
        margin-left: 5px;
        word-break: break-all;
      }
    }
    .credentials-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      cursor: pointer;

      .credentials {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }

      .credentials-number {
        font-weight: bold;
        font-size: 28px;
        margin-left: 5px;
        word-break: break-all;
      }
    }
  }

  .badge-section {
    border: 1px solid $color-grey;
    border-radius: 5px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 7px;
    .badge-image {
      height: 80px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  @media (max-width: $mobile-max) {
    .award-section {
      padding: 20px;
    }
  }

  .accent-color {
    background-color: #747474;
    height: 10px;
    width: 100%;
  }
}

.deployment-button{
  cursor: pointer !important;
  width: fit-content !important;
}

.deployments {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

