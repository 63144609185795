@import "styleUtil";

#filter-page-container {
  margin: 50px 0;
  grid-template-columns: 1fr minmax(auto, 1200px) 1fr;

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    grid-template-columns: 1fr 25fr 1fr;
    margin: 25px 0;
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
    grid-template-columns: 1fr 25fr 1fr;
  }

  .card-container {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 390px;
    margin: 50px 0 50px -25px;
    width: calc(100% + 25px);

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      width: 100%;
    }

    .loading-motion-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
    }
    .loading-cards {
      position: absolute;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9);
    }
  }

  .no-results-container {
    flex-basis: 100%;
    min-height: 50vh;

    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      min-height: auto;
      margin-left: 50px;
    }
  }

  #infinite-scroll-target {
    height: 1px;
  }
}
