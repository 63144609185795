@import "styleUtil";

.cmp-search {
  position: relative;
  /* Mobile */
  @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
    width: 100%;
  }

  .react-spectrum-Autocomplete,
  .spectrum-Search,
  .spectrum-Search input[type="search"] {
    @media only screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
      width: 100%;
    }
  }

  .clear-mobile-search {
    margin-top:70px;
    position: absolute;
    right: 24px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 37px;
    svg {
      width: 10px;
      height: 11px;
    }
  }
  .full-screen-dialog-searchbox input[type="search"],
  .full-screen-search-overlay .spectrum-Search,
  .full-screen-search-overlay .spectrum-Search .react-spectrum-Autocomplete {
    width: 100%;
  }

  .full-screen-dialog-searchbox {
    margin-top: 70px;
  }
}

.fs-autocomplete-popover {
  border: 0;
  box-shadow: none !important;
  background-color: transparent !important;
  border-top: 2px solid #e4e4e4 !important;
  margin-top: 15px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  ul li {
    font-size: 16px;
  }
}

.autocompleteStyle {
  input {    
    width: 300px !important;
    @media screen and (max-width: $mobile-max) {
      width: 100% !important;
    }
  }
}

.autocomplete-company-name {
  font-size: 14px;
}

.noResults{
  font-style: italic;
  padding: 10px !important;
}

.mobile-noresults {
  padding: 10px;
}

.result-wrapper {
  position: absolute;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
  margin-top: 10px;
  padding: 10px 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000026;
  border: 1px solid #CACACA;
  border-radius: 4px;
  box-sizing: border-box;
  opacity: 1;
  z-index: 2;
  @media only screen and (min-width: 240px) and (max-width: 768px) {
    width: 100%;
  }
}

.autocomplete-itemRow {
  padding: 7px 0;
  border-left: 2px solid white;
  cursor: pointer;

  &:hover {
    background: #bcbcbc33;
    border-left: 2px solid #2680eb;
  }

  .autocomplete-company-name {
    padding-left: 10px;
    font-size: 14px;
    display: inline-block;
  }
   @media only screen and (min-width: 240px) and (max-width: 768px) {
    width: 100%;
  }
}