.company-info-tab {
  h2 {
    color: #323232;
  }

  .subsidiary {
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    opacity: 1;
    padding: 16px;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .subsidiaryCompanyName {
    font-weight: bold;
  }

  .shortDescription {
    color: #505050;
    display: block;
    font-size: 26px;
    margin-bottom: 24px;
  }

  .longDescription {
    color: #505050;
    font-size: 16px;

    img {
      display: none;
    }

    a {
      color: #1473e6;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
