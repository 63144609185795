.toggle-filter-dialog {
  div[class^="spectrum-Dialog-grid"] {
    display: flex !important;
    flex-direction: column;
    padding: 20px 0px 20px 20px;
  }
}

.dropdown-upper-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropdown-title {
  width: 75%;
}

.dropdown-content {
  padding-top: 20px;
  span a {
    text-decoration: underline;
    color: #1473e6;
  }
}

.toggle-switch {
  position: relative;
  bottom: 5px;
}
